<template>
  <header class="bg-custom-gradient p-4 flex items-center justify-between  md:max-w-[670px] mx-auto fixed top-0 left-0 right-0 z-50 ">
    <div class="flex items-center space-x-4">
      <!-- Logo -->
      <img src="@/assets/maruay41-logo.webp" alt="Logo" class="h-10" />
    </div>
    <div class="flex space-x-2">
      <!-- Login Button -->
      <button
        @click="goToLogin"
        class="bg-yellow-500 text-black px-4 py-2 rounded-lg flex items-center space-x-2"
      >
        <span class="material-icons">account_circle</span>
        <span class="font-bold">เข้าสู่ระบบ</span>
      </button>


      <!-- Contact Admin Button -->
      <!-- <button
        @click="redirectToLine"
        class="bg-yellow-500 text-black px-4 py-2 rounded-lg flex items-center space-x-2"
      >
        <img src="@/assets/icons/line.png" alt="Line Icon" class="h-5" />
        <span class="font-bold">ติดต่อแอดมิน</span>
      </button> -->
    </div>
  </header>
  
</template>

<script>
export default {
  name: "Header",
  methods: {
    goToLogin() {
      this.$router.push({ name: "login" }); // นำทางไปยังหน้า login
    }
    // redirectToLine() {
    //   window.open("https://line.me/R/ti/p/@091rlmwa", "_blank");
    // }
  }
};
</script>

<style scoped>
.material-icons {
  font-size: 20px;
}
</style>
