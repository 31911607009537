<template>
    <div class="p-1 rounded-lg max-w-md mx-auto pt-20 md:max-w-[670px]">
      <!-- Header -->
      <HeaderLogin v-if="isLoggedIn" />
      <Header v-else />
  
      <!-- ปุ่มย้อนกลับ -->
      <div class="flex items-center space-x-2 mb-4 mt-5 px-4">
        <button class="bg-yellow-500 text-black px-4 py-2 rounded-lg flex items-center" @click="goBack">
          <span class="material-icons">arrow_back</span>
          <span>ย้อนกลับ</span>
        </button>
      </div>
  
      <!-- รูปเกมและข้อมูลเกมในรูปแบบ Card -->
      <div class="bg-[#171e79] p-2 rounded-lg mb-4 flex border border-yellow-500">
        <!-- รูปเกมด้านซ้าย -->
        <div class="w-1/3">
          <img :src="gameImage" alt="Game Image" class="w-full h-auto" />
        </div>
  
        <!-- ข้อมูลเกมด้านขวา -->
        <div class="w-2/3 pl-2 text-left">
          <h2 class="text-xl font-bold text-white">{{ gameName }}</h2>
          <p class="text-xl text-white font-bold">ค่าย : {{ gameProvider }}</p>
          <!-- ยอดถอน -->
          <div class="my-2">
            <img src="@/assets/icons/withdraw-icon.png" alt="withdraw icon" class="inline w-4 h-4 mr-2" />
            <span class="text-white">ยอดถอนรวมตอนนี้</span>
            <p class="text-yellow-400 text-3xl font-bold">{{ totalWithdrawValue.toLocaleString('th-TH') }} บาท</p>
          </div>
  
          <!-- แนะนำเกม -->
          <div class="mt-2">
            <img src="@/assets/icons/bet-icon.png" alt="bet icon" class="inline w-4 h-4 mr-2" />
            <span class="text-white">แนะนำเบท</span>
            <p class="text-white text-sm">
              เริ่มเบทแรกที่ <span class="text-yellow-400 text-2xl font-bold">{{ gameFirstBet }}</span> บาท
            </p>
            <p class="text-white text-sm">
              เริ่มเบทต่อไป <span class="text-yellow-400 text-2xl font-bold">{{ gameNextBet }}</span> บาท
            </p>
          </div>
        </div>
      </div>
  
      <div class="mt-4 text-center">
        <a href="https://mr41.net/account/" target="_blank"
           class="bg-yellow-400 text-black px-6 py-3 rounded-lg font-bold text-2xl inline-block w-[80%] zoom-animation">
          <img src="@/assets/icons/play-icon.png" alt="play icon" class="inline w-10 h-10 mr-2" />
          เล่นเกมนี้
        </a>
      </div>
  
      <!-- รายการยอดยูสเซอแตกเวลา -->
      <div class="mt-4">
        <h1 class="text-yellow-400 text-center font-bold text-2xl mb-4 flex justify-center items-end">
          <img src="@/assets/icons/fire.gif" alt="fire icon" class="w-16 h-16" />
          ยอดยูสเซอร์แตกเวลานี้
        </h1>
  
        <!-- ลิสต์รายการผู้ใช้งาน -->
        <div class="overflow-hidden h-[600px]" ref="userListContainer">
          <transition-group name="slide-fade" tag="div">
            <div v-for="user in displayedUsers" :key="user.id"
                 class="flex justify-between items-center bg-[#171e79] text-white p-2 rounded-lg mb-2 border border-yellow-500">
              <div class="flex items-center">
                <img src="@/assets/icons/user-icon.png" alt="user icon" class="inline w-5 h-5 mr-2" />
                <span class="font-bold">{{ user.name }}</span>
              </div>
              <p class="text-yellow-400 text-xl font-bold">{{ user.amount }} บาท</p>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </template>
  

  <script>
  import HeaderLogin from "@/components/HeaderLogin.vue";
  import Header from "@/components/Header.vue";
  import { mapState } from "vuex";
  
  export default {
    name: "GameDetail",
    components: {
      HeaderLogin,
      Header,
    },
    computed: {
      ...mapState(['isLoggedIn']), // ดึง isLoggedIn จาก Vuex Store
      gameProvider() {
        return this.$route.params.gameProvider;
      },
      gameName() {
        return this.$route.params.gameName;
      },
      gameImage() {
        return this.$route.params.gameImage;
      },
      gameFirstBet() {
        const betOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        let firstBet = betOptions[Math.floor(Math.random() * betOptions.length)];
        let secondBet = betOptions[Math.floor(Math.random() * betOptions.length)];
  
        while (firstBet === secondBet) {
          secondBet = betOptions[Math.floor(Math.random() * betOptions.length)];
        }
  
        const minBet = Math.min(firstBet, secondBet);
        const maxBet = Math.max(firstBet, secondBet);
  
        return `${minBet}-${maxBet}`;
      },
      gameNextBet() {
        const betRanges = [
          { min: 100, max: 150 },
          { min: 200, max: 250 },
          { min: 300, max: 350 },
          { min: 400, max: 450 }
        ];
  
        const randomRange = betRanges[Math.floor(Math.random() * betRanges.length)];
        return `${randomRange.min}-${randomRange.max}`;
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      totalWithdraw() {
        const randomAmount = Math.floor(Math.random() * (10000000 - 500000 + 1)) + 500000;
        this.totalWithdrawValue = randomAmount;
  
        const incrementWithdrawValue = () => {
          this.totalWithdrawValue += 10000;
          this.displayedWithdrawValue = this.totalWithdrawValue.toLocaleString('th-TH');
          setTimeout(incrementWithdrawValue, 2000);
        };
  
        incrementWithdrawValue();
      },
      initializeUsers() {
        const userNames = Array.from({ length: 100 }, () => "MrzXXXXX");
        const usedAmounts = new Set();
  
        while (usedAmounts.size < 100) {
          const randomAmount = Math.floor(Math.random() * (50000 - 100 + 1)) + 100;
          usedAmounts.add(randomAmount);
        }
  
        const randomAmounts = [...usedAmounts];
  
        this.userList = userNames.map((name, index) => ({
          id: index + 1,
          name: name,
          amount: randomAmounts[index].toLocaleString(),
        }));
  
        this.displayedUsers = this.userList.slice(0, 10);
      },
      startUserScroll() {
        setInterval(() => {
          this.displayedUsers.shift();
          const nextUser = this.userList[this.currentIndex];
          this.displayedUsers.push(nextUser);
          this.currentIndex = (this.currentIndex + 1) % this.userList.length;
        }, 2000);
      },
    },
    mounted() {
      this.initializeUsers();
      this.totalWithdraw();
      setTimeout(() => this.startUserScroll(), 1000);
    },
    data() {
      return {
        userList: [],
        displayedUsers: [],
        currentIndex: 10,
        totalWithdrawValue: 0,
      };
    }
  };
  </script>
  


<style scoped>

.overflow-hidden {
    height: 600px;
    /* ปรับความสูงเป็น 600px เพื่อให้แสดง 10 รายการ */
    position: relative;
}

.slide-fade-enter-active {
    transition: all 1s ease;
}

.slide-fade-leave-active {
    transition: all 1s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}

@keyframes zoomInOut {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
        /* ขยายที่จุดกึ่งกลาง */
    }

    100% {
        transform: scale(1);
        /* กลับไปขนาดเดิม */
    }
}

.zoom-animation {
    animation: zoomInOut 2s ease-in-out infinite;
    /* ใช้ Animation ที่วนลูปตลอดเวลา */
}
</style>
