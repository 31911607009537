<template>
  <header
    class="bg-custom-gradient p-3 flex items-center justify-between md:max-w-[670px] mx-auto fixed top-0 left-0 right-0 z-50">
    <div class="flex items-center space-x-4">
      <!-- Logo -->
      <img src="@/assets/maruay41-logo.webp" alt="Logo" class="h-10" />
    </div>

    <div class="flex items-center space-x-4">
      <div
        class="flex items-center justify-center bg-coin text-white px-4 py-1 rounded-full shadow-lg w-auto space-x-2">
        <img src="/hand.gif" alt="Hand Icon" class="w-6 h-6">
        <span class="text-sm font-bold">{{ username }}</span>
      </div>

      <!-- Sign Out Button -->
      <button @click="signOut" class="ml-1 mt-1 text-white rounded-full p-2 transition-all">
        <span class="material-icons">logout</span>
      </button>
    </div>
  </header>
</template>

<script>
import { auth } from "@/config/firebase";
import { mapState, mapMutations } from 'vuex';

export default {
  name: "HeaderLogin",
  computed: {
    ...mapState({
      username: (state) => state.user.username,
      phoneNumber: state => state.user.phoneNumber,
    }),
  },
  methods: {
    ...mapMutations(['clearUser']), // เพิ่ม mapMutations สำหรับ clearUser
    async signOut() {
      try {
        await auth.signOut();
        this.clearUser(); // เรียก clearUser เพื่อเคลียร์ข้อมูลผู้ใช้ใน Vuex
        this.$router.push({ name: "MainLayout" });
      } catch (error) {
        console.error("Error signing out:", error);
      }
    }
  }
};
</script>

<style scoped>
.material-icons {
  font-size: 24px;
}

.bg-custom-gradient {
  background: linear-gradient(135deg, #172f82, #180650);
}

.bg-coin {
  background: linear-gradient(135deg, #172f82, #180650);
  border: 1px solid #FFD700;
}

.gold-gradient {
  background: linear-gradient(135deg, #FFD700, #FFC700, #FFB700);
  border-top: 4px solid #ffd84c;
  border-left: 4px solid #ffd84c;
  border-right: 4px solid #d57e08;
  border-bottom: 4px solid #d57e08;
}

.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
}

.transition-all {
  transition: all 0.3s ease;
}
</style>
