<template>
  <div class="min-h-screen flex items-center justify-center bg-[#e4e9f7] relative">
    <!-- รูปพื้นหลัง -->
    <div class="absolute inset-0 z-0">
      <img src="@/assets/background/login-background.png" alt="Background Image" class="w-full h-full object-cover" />
    </div>

    <button @click="goBack"
      class="bg-yellow-400 text-black px-3 py-2 rounded-full absolute top-4 left-4 flex items-center">
      <span class="material-icons">arrow_back</span>
    </button>

    <!-- Container สำหรับฟอร์มล็อกอิน -->
    <div class="bg-[#171e79] border border-[#EECE61] p-8 rounded-xl shadow-lg w-[360px] relative z-10">
      <!-- Logo -->
      <div class="text-center mt-10 mb-8">
        <img src="@/assets/maruay41-logo.webp" alt="Logo" class="mx-auto" />
      </div>

      <!-- Input Username -->
      <div class="mb-4">
        <label for="username" class="block text-white mb-2">ยูสเซอร์ในเว็ป</label>
        <input id="username" type="text" v-model="username"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          placeholder="กรอกยูสเซอร์ในเว็พ Mrz" />
      </div>

      <!-- Input Phone Number -->
      <div class="mb-4 relative">
        <label for="phoneNumber" class="block text-white mb-2">หมายเลขมือถือ ที่สมัคในเว็ป</label>
        <div class="relative">
          <input id="phoneNumber" type="text" v-model="phoneNumber"
            class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            placeholder="กรอกหมายเลขมือถือ" />
        </div>
      </div>

      <!-- Consent Checkbox -->
      <div class="mb-4">
        <label class="block text-white">
          <input type="checkbox" v-model="consent" />
          ยินยอมรับการแจ้งเตือน
        </label>
      </div>

      <!-- แจ้งเตือน -->
      <div class="bg-[#fff4e5] text-[#805300] rounded-lg p-4 flex items-center justify-between mb-6 shadow-sm"
        v-if="alertMessage">
        <div class="flex items-center">
          <span class="material-icons mr-2">error_outline</span>
          <p class="text-sm">{{ alertMessage }}</p>
        </div>
        <button @click="closeAlert" class="focus:outline-none">
          <span class="material-icons">close</span>
        </button>
      </div>

      <!-- Login Button -->
      <button @click="handleLogin"
        class="bg-yellow-400 text-black px-4 py-3 w-full rounded-lg font-bold text-lg hover:bg-yellow-500 transition-colors">
        เข้าสู่ระบบ
      </button>
    </div>

    <!-- Toast for Loading -->
    <div v-if="isLoading" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div class="bg-white p-6 rounded-lg flex items-center gap-4 shadow-lg">
        <span class="material-icons animate-spin">autorenew</span>
        <p>กำลังดำเนินการ โปรดรอสักครู่...</p>
      </div>
    </div>

    <!-- Success Toast -->
    <div v-if="showToast" class="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded shadow-lg">
      {{ toastMessage }}
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import axiosInstance from "@/config/axios";
import apiPath from "@/config/apiPath";
import { auth, signInAnonymously } from "@/config/firebase";

export default {
  data() {
    return {
      username: "",
      phoneNumber: "",
      consent: false,
      alertMessage: "",
      subscription: null,
      vapidPublicKey: process.env.VUE_APP_VAPID_PUBLIC_KEY,
      isLoading: false, // สำหรับแสดง Loading
      showToast: false, // สำหรับแสดง Toast
      toastMessage: "", // ข้อความ Toast
    };
  },
  methods: {
    ...mapMutations(['setUser']),
    async handleLogin() {
      if (!this.consent) {
        this.alertMessage = "กรุณาคลิกยินยอมรับการแจ้งเตือนก่อนเข้าสู่ระบบ";
        return;
      }

      this.isLoading = true; // เริ่มแสดง Loading

      try {
        // เข้าสู่ระบบ Firebase
        const userCredential = await this.firebaseLogin();
        const firebaseUid = userCredential.user.uid;
        const idToken = await userCredential.user.getIdToken();

        // ตรวจสอบผู้ใช้ในระบบ
        const response = await axiosInstance.post(apiPath.CHECK_USER, {
          username: this.username,
          phoneNumber: this.phoneNumber,
          firebaseUid
        }, {
          headers: { Authorization: `Bearer ${idToken}` }
        });

        // console.log("Response from CHECK_USER API:", response.data);

        if (response.data.exists) {
          let userId = response.data._id;
          if (!userId) throw new Error("ไม่พบ userId ในการตอบกลับจาก API");

          userId = userId.toString();
          await this.authenticateUser(idToken, firebaseUid);

          // เก็บข้อมูลผู้ใช้ใน Vuex
          this.$store.commit('setUser', {
            username: this.username,
            phoneNumber: this.phoneNumber,
            firebaseUid,
            idToken,
            userId
          });

          // สมัคร Push Notification ในเบื้องหลัง
          this.registerServiceWorker().catch(error => {
            console.warn("ไม่สามารถสมัครรับการแจ้งเตือน:", error.message);
          });

          this.showToastMessage("เข้าสู่ระบบสำเร็จ!");
        } else {
          await this.registerUser(idToken, firebaseUid, null);
        }
      } catch (error) {
        console.error("เกิดข้อผิดพลาดระหว่างการเข้าสู่ระบบ:", error.message || error);
        this.alertMessage = error.response?.data || "เกิดข้อผิดพลาดในการเข้าสู่ระบบ";
      } finally {
        this.isLoading = false; // หยุดแสดง Loading
      }
    },

    async registerServiceWorker() {
      try {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(this.vapidPublicKey),
        });
        return subscription.toJSON();
      } catch (error) {
        throw new Error("ไม่สามารถสมัครรับการแจ้งเตือนได้");
      }
    },

    async firebaseLogin() {
      try {
        if (auth.currentUser) {
          await auth.signOut();
        }
        return await signInAnonymously(auth);
      } catch (error) {
        throw new Error("ไม่สามารถเชื่อมต่อกับ Firebase ได้");
      }
    },

    async authenticateUser(idToken, firebaseUid) {
      try {
        const response = await axiosInstance.post(apiPath.LOGIN, {
          username: this.username,
          phoneNumber: this.phoneNumber
        }, {
          headers: { Authorization: `Bearer ${idToken}` }
        });

        if (response.data.message === "Login successful") {
          this.setUser({
            username: this.username,
            phoneNumber: this.phoneNumber,
            firebaseUid,
            idToken
          });
          this.$router.push({ name: 'MainLayoutAfterlogin' });
        } else {
          this.alertMessage = "หมายเลขมือถือ ที่สมัครในเว็บไม่ถูกต้อง";
        }
      } catch (error) {
        this.alertMessage = error.response?.data.message || "เกิดข้อผิดพลาดในการเข้าสู่ระบบ";
      }
    },

    async registerUser(idToken, firebaseUid, subscription) {
      try {
        const userAgent = navigator.userAgent;
        await axiosInstance.post(apiPath.REGISTER, {
          firebaseUid,
          username: this.username,
          phoneNumber: this.phoneNumber,
          consent: this.consent,
          device: userAgent,
          subscription
        }, {
          headers: { Authorization: `Bearer ${idToken}` }
        });

        this.setUser({
          username: this.username,
          phoneNumber: this.phoneNumber,
          firebaseUid,
          idToken
        });

        this.$router.push({ name: 'MainLayoutAfterlogin' });
      } catch (error) {
        this.alertMessage = error.response?.data || "เกิดข้อผิดพลาดในการลงทะเบียน";
      }
    },

    showToastMessage(message) {
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
        this.toastMessage = "";
      }, 3000); // Toast จะหายไปหลัง 3 วินาที
    },

    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>