<template>
  <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
    <!-- Header -->
    <HeaderLogin />
    <BtnBack />

    <div class="flex justify-between items-end w-full mb-4">
      <h4 class="text-lg text-white font-bold flex items-end">
        <img src="/assets/icons/fire.gif" alt="fire icon" class="w-10 h-10 flex-shrink-0 self-end mr-2" />
        ทายผลหวย
      </h4>
      <button @click="goToHistory" class="text-white text-xs font-bold flex items-center hover:underline mr-4">
        <span class="material-icons mr-1 flex-shrink-0">history</span>
        ประวัติทายผลหวย
      </button>
    </div>

    <!-- Logo และ ทายหวย -->
    <div class="flex flex-col items-center mb-6">
      <img src="@/assets/icons/maruay41-logo.png" alt="Logo" class="w-28 h-28 mb-4" />
      <h2 class="text-white text-lg font-bold mb-3">ทายผลหวย เลขท้ายสองตัวกับเรา</h2>

      <p v-if="!currentRound.round_date" class="text-yellow-500 text-sm text-center">
        งวดทายหวยยังไม่เปิดให้ทายนะคะ<br />
        รอติดตามวันเปิดใหม่อีกครั้งนะคะ
      </p>

      <p v-else class="text-white text-sm text-center">
        งวดวันที่ {{ currentRound.round_date }}
        <span v-if="currentRound.remainingDays > 0"
          class="text-black bg-yellow-500 font-bold py-1 px-2 rounded-lg ml-2">
          เหลือเวลาอีก {{ currentRound.remainingDays }} วัน
        </span>
      </p>

    </div>

    <!-- แสดงจำนวนการทายผลที่เหลือ -->
    <p
      class="bg-[#fff5e4] text-[#815b2a] border border-[#EECE61] mt-6 p-4 rounded-lg shadow-lg mx-4 flex flex-col items-center">
      Credit จำนวนทายผล = {{ remainingGuesses }} /
      {{ currentRound.guess_limit || 0 }}
    </p>

    <!-- กล่องกรอกข้อมูล -->
    <div
      class="bg-gradient-to-br from-[#172f82] to-[#180650] border border-[#EECE61] p-4 rounded-lg shadow-lg text-center mt-4 mx-4">
      <div class="flex justify-center space-x-2 mb-4">
        <input v-model="lotteryNumber[0]" type="text"
          class="w-12 h-12 text-center text-lg font-bold rounded-[5px] border-2 border-gray-300" maxlength="1" />
        <input v-model="lotteryNumber[1]" type="text"
          class="w-12 h-12 text-center text-lg font-bold rounded-[5px] border-2 border-gray-300" maxlength="1" />
      </div>

      <div class="mb-4">
        <label class="text-white flex items-center justify-center space-x-2 mb-2">
          <span class="material-icons">phone</span>
          <span>เบอร์โทรศัพท์ (ที่สมัครในเว็บ)</span>
        </label>
        <input v-model="phoneNumber" type="text" class="w-full p-2 rounded border font-bold border-gray-300" readonly />
      </div>
      <!-- ปุ่มทายผล -->
      <button @click="submitGuess" :disabled="!currentRound._id || remainingGuesses <= 0"
        class="bg-gradient-to-br from-[#172f82] to-[#180650] text-white px-6 py-2 rounded-lg font-bold border-2 border-yellow-500 shadow-lg hover:bg-blue-700 transition-all">
        <!-- เงื่อนไขข้อความในปุ่ม -->
        {{
          !currentRound._id
            ? "รอเปิดกิจกรรม"
            : remainingGuesses <= 0 ? "ทายผลครบจำนวนแล้ว" : "ทายผลหวย" }} </button>
    </div>

    <!-- Toast Notification -->
    <div v-if="toastMessage"
      class="toast-message fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg">
      {{ toastMessage }}
    </div>
  </div>
</template>

<script>
import HeaderLogin from "@/components/HeaderLogin.vue";
import BtnBack from "@/components/BtnBack.vue";
import { mapState } from "vuex";
import axios from "axios";
import apiPath from "@/config/apiPath";

export default {
  name: "LotteryView",
  components: {
    HeaderLogin,
    BtnBack,
  },
  data() {
    return {
      lotteryNumber: ["", ""],
      alertMessage: "",
      toastMessage: "",
      currentRound: {}, // ข้อมูลรอบหวยปัจจุบัน
      remainingGuesses: 0, // จำนวนทายผลที่เหลือ
    };
  },
  computed: {
    ...mapState({
      username: (state) => state.user.username,
      phoneNumber: (state) => state.user.phoneNumber,
    }),
  },
  async mounted() {
    try {
      const response = await axios.get(apiPath.ACTIVE_ROUND_AND_CREDIT(this.username || null));
      const { currentRound, remainingGuesses, message } = response.data;

      if (currentRound && currentRound.round_date && currentRound.end_date) {
        const roundDate = new Date(currentRound.round_date);
        const endDate = new Date(currentRound.end_date);

        currentRound.round_date = roundDate.toISOString().split("T")[0];
        currentRound.end_date = endDate.toISOString().split("T")[0];

        // คำนวณจำนวนวันที่เหลือ
        const today = new Date();
        const remainingDays = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24)); // แปลง ms เป็นวัน
        currentRound.remainingDays = remainingDays > 0 ? remainingDays : 0; // ถ้าวันที่เหลือน้อยกว่า 0 ให้แสดงเป็น 0
      }

      this.currentRound = currentRound || {}; // ตั้งค่า currentRound
      this.remainingGuesses = remainingGuesses || 0;
      this.alertMessage = message || ""; // แจ้งเตือนถ้ามี message
    } catch (error) {
      console.error("Error fetching active round and credit:", error.response?.data || error.message);
      this.alertMessage = "ไม่สามารถดึงข้อมูลงวดหวยได้ในขณะนี้";
      this.currentRound = {}; // หากดึงข้อมูลไม่ได้ ให้ตั้งค่า currentRound เป็น {}
      this.remainingGuesses = 0;
    }
  },
  methods: {
    async submitGuess() {
      if (!this.currentRound || !this.currentRound._id) {
        this.alertMessage = "ไม่สามารถบันทึกการทายผลได้ เนื่องจากไม่มีงวดหวยที่กำลังดำเนินการ";
        return;
      }

      if (!this.lotteryNumber[0] || !this.lotteryNumber[1]) {
        this.alertMessage = "กรุณาใส่เลขทายผล";
        return;
      }

      try {
        const guessLotteryNumber = this.lotteryNumber.join("");
        const currentRoundId = this.currentRound._id;
        const dateGuess = new Date().toISOString();

        await axios.post(apiPath.CREATE_LOTTERY_GUESS, {
          username: this.username,
          phoneNumber: this.phoneNumber,
          currentRoundId,
          guessNumber: guessLotteryNumber,
          dateGuess,
        });

        const response = await axios.get(apiPath.ACTIVE_ROUND_AND_CREDIT(this.username || null));
        const { remainingGuesses } = response.data;

        this.remainingGuesses = remainingGuesses; // Update credit
        this.lotteryNumber = ["", ""]; // Reset input
        this.showToast("บันทึกการทายผลสำเร็จ!");
      } catch (error) {
        console.error("Error in submitGuess:", error.response?.data || error.message);
        this.alertMessage = "เกิดข้อผิดพลาดในการทายผล";
      }
    },
    showToast(message) {
      this.toastMessage = message;
      setTimeout(() => {
        this.toastMessage = "";
      }, 3000);
    },
    goToHistory() {
      this.$router.push("/lottery-history");
    },
  },
};
</script>



<style scoped>
.toast-message {
  position: fixed;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffee00ee;
  color: rgb(0, 0, 0);
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
</style>
